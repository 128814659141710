import React from 'react';
import { useSelector } from 'react-redux';
import Unauthorized from '../containers/Unauthorized';
import { RootState } from '../store/Entities';

// const unauthorizedPage = () => (

//     <div>You must be logged in to view this page</div>
// );

export const defaultRole = 'bankadministrator';

export const PageContainer = (props: {
  protected?: boolean;
  allRoles?: boolean;
  role?: string;
  children: React.ReactNode;
}) => {
  const user = useSelector((state: RootState) => state.user);
  if (props?.protected) {
    if (!user || !user.isLoggedIn) {
      return <Unauthorized />;
    }
  }
  return <>{props.children}</>;
};
