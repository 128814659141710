export const AuthServiceUrl = 'https://wexp-authserver.azurewebsites.net';
export const GatewayUrl = 'https://wexp-wbankgateway.azurewebsites.net/';
export const GatewayApiUrl = GatewayUrl + 'api';

export const Endpoints = {
  Auth: {
    ConnectToken: `${AuthServiceUrl}/connect/token`,
  },
  AccountHolder: {
    GetIndividualAccountHolder: (userId) =>
      `${GatewayApiUrl}/AccountHolder/GetIndividualAccountHolder/${userId}`,
  },
  BalanceAccountGroup: {
    Overview: (userId) =>
      `${GatewayApiUrl}/BalanceAccountGroup/Overview?UserID=${userId}`,
  },
  Statement: {
    Overview: (userId) =>
      `${GatewayApiUrl}/Statement/StatementOverview?UserID=${userId}`,
  },
};
