// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// @ts-ignore
import { apiCallBegan } from '../api';

import {
  IState,
  IBalanceOverviewResult,
} from '../Interfaces/BalanceAccountGroup';
import { Endpoints } from '../../constants/endpoints';
import { IApi } from '../middleware/interfaces/api';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  overviewData: {
    user: '',
    benefitsTotalBalance: 0,
    benefitsTotalCorp: 0,
    benefitsTotalYou: 0,
    options: [],
    benefits: [],
    otherBalances: [],
  },
};

export const slice = createSlice({
  name: 'balanceAccountGroup',
  initialState,
  reducers: {
    BALANCE_ACCOUNT_GROUP_REQUESTED: (
      state: IState,
      action: PayloadAction<IState>
    ) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },

    BALANCE_ACCOUNT_GROUP_FAILED: (
      state: IState,
      action: PayloadAction<IState>
    ) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    BALANCE_ACCOUNT_GROUP_SUCCESSFULLY: (
      state: IState,
      action: PayloadAction<{ result: IBalanceOverviewResult }>
    ) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.overviewData = action.payload.result;
    },
  },
});

export const {
  BALANCE_ACCOUNT_GROUP_REQUESTED,
  BALANCE_ACCOUNT_GROUP_FAILED,
  BALANCE_ACCOUNT_GROUP_SUCCESSFULLY,
} = slice.actions;

export default slice.reducer;

export const GetBalanceAccountGroupOverview = (userId: string) => {
  const request: IApi = {
    axiosConfig: {
      url: Endpoints.BalanceAccountGroup.Overview(userId),
      method: 'get',
    },
    onAction: {
      onStart: BALANCE_ACCOUNT_GROUP_REQUESTED.type,
      onSuccess: BALANCE_ACCOUNT_GROUP_SUCCESSFULLY.type,
      onError: BALANCE_ACCOUNT_GROUP_FAILED.type,
    },
  };
  return apiCallBegan(request);
};
