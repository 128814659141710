import React from 'react';
import Navbar from '../components/Navbar';

import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid2';
import WexpIconUnauthorizedOrange from '../assets/images/unathorized.png';
import Button from '@mui/material/Button';
import { RootState } from '../store/Entities';

const Unauthorized: React.FC = () => {
  const user = useSelector((state: RootState) => state.user);

  return (
    <>
      {user.isLoggedIn && <Navbar />}

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Grid
          size={{
            xs: 6,
            md: 8,
          }}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            width: '100%',
          }}
        >
          <img
            src={WexpIconUnauthorizedOrange}
            style={{ width: 500, height: 500 }}
            alt="Unauthorized Icon"
          />
          {user.isLoggedIn ? (
            <p>
              Você não tem permissão para visualizar essa página. Caso isso seja
              um erro, contate o suporte.
            </p>
          ) : (
            <p>Você precisa estar autenticado para acessar essa página.</p>
          )}
        </Grid>
        <Button
          href={user.isLoggedIn ? '/home' : '/login'}
          variant="contained"
          sx={{ width: '200px', marginBottom: '40px' }}
        >
          {user.isLoggedIn ? 'Voltar' : 'Fazer Login'}
        </Button>
      </Grid>
    </>
  );
};

export default Unauthorized;
