import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Button, Modal } from '@mui/material';
import logo from '../assets/images/logo-wexp-plus.png';
import waveBg from '../assets/images/wave.gif';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/Entities';
import { USER_LOGOUT } from '../store/Entities/User';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 150,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 4,
};

const Navbar: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const userName = user.data?.firstName;

  const [showModal, setShowModal] = useState(false);

  const handleLogout = () => {
    dispatch(USER_LOGOUT());
    navigate('/login');
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundImage: `url(${waveBg})`,
          backgroundSize: 'cover',
          height: '130px',
          display: 'flex',
          justifyContent: 'center',
          boxShadow: 'none',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={logo}
              alt="Company Logo"
              style={{ height: '80px', objectFit: 'contain' }}
            />
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ marginRight: 1 }}>
              {userName}
            </Typography>
            <IconButton color="inherit" onClick={() => setShowModal(true)}>
              <LogoutIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box sx={style}>
          <Typography variant="h6" textAlign={'center'}>
            Tem certeza que deseja sair?
          </Typography>
          <Box display={'flex'} justifyContent={'space-around'}>
            <Button variant="contained" onClick={handleLogout}>
              CONFIRMAR
            </Button>
            <Button variant="outlined" onClick={() => setShowModal(false)}>
              CANCELAR
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Navbar;
