import axios, { AxiosRequestConfig } from 'axios';
import { Middleware } from '@reduxjs/toolkit';

// Actions
import * as actions from '../api';

// Initialize Axios instance
const api = axios.create();

// Add request interceptor for authorization
api.interceptors.request.use((config) => {
  const access_token = localStorage.getItem('access_token');
  if (access_token && typeof access_token === 'string') {
    config.headers.Authorization = `Bearer ${access_token}`;
  }
  return config;
});

// Add response interceptor for error handling
api.interceptors.response.use(
  (response) => response,
  async (error) => Promise.reject(error)
);

// Define the middleware
const callApi: Middleware = (store) => (next) => async (action: any) => {
  if (action.type !== actions.apiCallBegan.type) return next(action);

  const { dispatch } = store;
  const {
    onAction,
    axiosConfig,
  }: {
    onAction?: {
      onStart?: string;
      onSuccess?: string;
      onError?: string;
    };
    axiosConfig: AxiosRequestConfig;
  } = action.payload;

  console.info('callApi', axiosConfig);

  // Dispatch the start action if provided
  if (onAction?.onStart) dispatch({ type: onAction.onStart });

  // Pass the action along the middleware chain
  next(action);

  try {
    const response = await api.request(axiosConfig);
    if (onAction?.onSuccess) {
      // Dispatch success actions
      dispatch(actions.apiCallSuccess(response.data));
      dispatch({ type: onAction.onSuccess, payload: response.data });
    }
  } catch (error: any) {
    if (onAction?.onError) {
      // Dispatch error actions
      dispatch({ type: onAction.onError, payload: error.response });
    }
  }
};

export default callApi;
