import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import { Box, Typography } from '@mui/material';
import CardItem from '../components/CardItem';
import InfoAndHistory from '../components/InfoAndHistory';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/Entities';
import { GetBalanceAccountGroupOverview } from '../store/Entities/BalanceAccountGroup';
import { IBalanceAccountResult } from '../store/Interfaces/BalanceAccountGroup';
import { GetStatementOverview } from '../store/Entities/Statement';

import alimentacao_icon from '../assets/images/alimentacao-icon.png';
import combustivel_icon from '../assets/images/combustivel-icon.png';
import cultura_icon from '../assets/images/cultura-icon.png';
import educacao_icon from '../assets/images/educacao-icon.png';
import homeOffice_icon from '../assets/images/home-office-icon.png';
import mobilidade_icon from '../assets/images/mobilidade-icon.png';
import refeicao_icon from '../assets/images/refeicao-icon.png';
import saude_icon from '../assets/images/saude-icon.png';
import transporte_icon from '../assets/images/transporte-icon.png';

interface IBenefitsData {
  name: string;
  value: string;
}

interface IFreeBalanceData {
  balanceAccountType: string;
  name: string;
  value: string;
}

interface IHistoryData {
  name: string;
  value: string;
  date: string;
}

const Home: React.FC = () => {
  const balanceAccountGroup = useSelector(
    (state: RootState) => state.balanceAccountGroup
  );
  const user = useSelector((state: RootState) => state.user);
  const statement = useSelector((state: RootState) => state.statement);
  const dispatch = useDispatch();

  const [cardsData, setCardsData] = useState<IBalanceAccountResult[]>([]);
  const [benefitsData, setBenefitsData] = useState<IBenefitsData[]>([]);
  const [freeBalance, setFreeBalance] = useState<IFreeBalanceData[]>([]);
  const [historyData, setHistoryData] = useState<IHistoryData[]>([]);

  useEffect(() => {
    if (user.authData?.sub) {
      const userID = user.authData.sub;
      dispatch(GetBalanceAccountGroupOverview(userID));
      dispatch(GetStatementOverview(userID));
    }
  }, [dispatch, user.authData.sub]);

  useEffect(() => {
    if (
      balanceAccountGroup.success &&
      balanceAccountGroup.overviewData &&
      !balanceAccountGroup.error
    ) {
      setCardsData(balanceAccountGroup.overviewData.benefits);
      setBenefitsData([
        {
          name: 'CORP',
          value: `R$ ${balanceAccountGroup.overviewData.benefitsTotalCorp
            .toFixed(2)
            .replace('.', ',')}`,
        },
        {
          name: 'YOU',
          value: `R$ ${balanceAccountGroup.overviewData.benefitsTotalYou
            .toFixed(2)
            .replace('.', ',')}`,
        },
        {
          name: 'Total em benefícios',
          value: `R$ ${balanceAccountGroup.overviewData.benefitsTotalBalance
            .toFixed(2)
            .replace('.', ',')}`,
        },
      ]);
      setFreeBalance(
        balanceAccountGroup.overviewData.otherBalances.map((balance) => {
          return {
            balanceAccountType: balance.balanceAccountType,
            name: balance.description,
            value: `R$ ${balance.balance.toFixed(2).replace('.', ',')}`,
          };
        })
      );
    }

    if (balanceAccountGroup.error) {
      setCardsData([]);
      setBenefitsData([]);
      setFreeBalance([]);
    }
  }, [balanceAccountGroup]);

  useEffect(() => {
    if (statement.success && statement.overviewData && !statement.error) {
      setHistoryData(
        statement.overviewData.transactions.map((transaction) => {
          return {
            name: transaction.description,
            value: `R$ ${transaction.amount.toFixed(2).replace('.', ',')}`,
            date: transaction.date,
          };
        })
      );
    }
    if (statement.error) {
      setHistoryData([]);
    }
  }, [statement]);

  return (
    <div>
      <Navbar />
      <Box sx={{ padding: 4 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          BENEFÍCIOS / WALLET
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
            gap: 4,
            maxWidth: '1200px',
            margin: '0 auto',
          }}
        >
          {cardsData.map((card, index) => {
            const cardIcons = {
              Alimentação: alimentacao_icon,
              Combustível: combustivel_icon,
              Cultura: cultura_icon,
              Educação: educacao_icon,
              'Home Office': homeOffice_icon,
              Mobilidade: mobilidade_icon,
              Refeição: refeicao_icon,
              Saúde: saude_icon,
              Transporte: transporte_icon,
            };

            const IconComponent = () => {
              const icon =
                cardIcons[card.description as keyof typeof cardIcons];

              return (
                <img
                  style={{
                    width: '40px',
                  }}
                  src={icon}
                  alt={card.description}
                />
              );
            };

            return (
              <CardItem
                key={index}
                title={card.description}
                Icon={IconComponent}
                price={`R$${card.balance.toFixed(2).replace('.', ',')}`}
                type={card.description}
                isYou={card.balanceAccountType === 'You'}
              />
            );
          })}
        </Box>
      </Box>
      <InfoAndHistory
        benefitsData={benefitsData}
        freeBalance={freeBalance}
        historyData={historyData}
      />
    </div>
  );
};

export default Home;
