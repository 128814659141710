// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// @ts-ignore
import { apiCallBegan } from '../api';

import { IState, IStatementOverviewResult } from '../Interfaces/Statement';
import { Endpoints } from '../../constants/endpoints';
import { IApi } from '../middleware/interfaces/api';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  overviewData: {
    benefits: [],
    transactions: [],
  },
};

export const slice = createSlice({
  name: 'statement',
  initialState,
  reducers: {
    STATEMENT_REQUESTED: (state: IState, action: PayloadAction<IState>) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },

    STATEMENT_FAILED: (state: IState, action: PayloadAction<IState>) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    STATEMENT_SUCCESSFULLY: (
      state: IState,
      action: PayloadAction<{ result: IStatementOverviewResult }>
    ) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.overviewData = action.payload.result;
    },
  },
});

export const { STATEMENT_REQUESTED, STATEMENT_FAILED, STATEMENT_SUCCESSFULLY } =
  slice.actions;

export default slice.reducer;

export const GetStatementOverview = (userId: string) => {
  const request: IApi = {
    axiosConfig: {
      url: Endpoints.Statement.Overview(userId),
      method: 'get',
    },
    onAction: {
      onStart: STATEMENT_REQUESTED.type,
      onSuccess: STATEMENT_SUCCESSFULLY.type,
      onError: STATEMENT_FAILED.type,
    },
  };
  return apiCallBegan(request);
};
