import React, { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Divider,
  TablePagination,
  TableSortLabel,
  Button,
} from '@mui/material';
import * as XLSX from 'xlsx';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import livre_icon from '../assets/images/livre-icon.png';

const compare = (a: any, b: any, orderBy: string, order: 'asc' | 'desc') => {
  const valueA = a[orderBy];
  const valueB = b[orderBy];
  if (order === 'asc') return valueA < valueB ? -1 : 1;
  return valueA > valueB ? -1 : 1;
};

const InfoAndHistory: React.FC<{
  benefitsData: Array<{ name: string; value: string }>;
  freeBalance: Array<{
    balanceAccountType: string;
    name: string;
    value: string;
  }>;
  historyData: Array<{ name: string; value: string; date: string }>;
}> = ({ benefitsData, freeBalance, historyData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('date');

  const YouFreeBalance = freeBalance.find(
    (balance) => balance.balanceAccountType === 'You'
  );

  const CorpFreeBalance = freeBalance.find(
    (balance) => balance.balanceAccountType === 'Corp'
  );

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(historyData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Histórico');
    XLSX.writeFile(workbook, 'historico.xlsx');
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = [...historyData].sort((a, b) =>
    compare(a, b, orderBy, order)
  );
  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{ display: 'flex', height: '100%', padding: 6 }}>
      <Box sx={{ width: '30%', padding: 2 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          SALDOS
        </Typography>

        <Box sx={{ marginBottom: 2 }}>
          {benefitsData.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: index < benefitsData.length - 1 ? 1 : 0,
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: index === 2 ? 'bold' : 'normal' }}
              >
                {item.name}
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontWeight: index === 2 ? 'bold' : 'normal' }}
              >
                {item.value}
              </Typography>
            </Box>
          ))}
        </Box>

        <Divider sx={{ marginBottom: 2 }} />

        <Box
          sx={{
            backgroundColor: '#ee7330',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: 2,
            padding: 2,
            marginBottom: 2,
            color: '#fff',
          }}
        >
          {YouFreeBalance && (
            <>
              <img
                src={livre_icon}
                style={{ width: 40 }}
                alt="You - Saldo Livre"
              />
              <Typography variant="body1">{`${YouFreeBalance.balanceAccountType} - SALDO LIVRE`}</Typography>
              <Typography variant="body1">{YouFreeBalance.value}</Typography>
            </>
          )}
        </Box>

        <Box
          sx={{
            backgroundColor: '#000',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: 2,
            padding: 2,
            color: '#fff',
          }}
        >
          {CorpFreeBalance && (
            <>
              <img
                src={livre_icon}
                style={{ width: 40 }}
                alt="CORP - Saldo Livre"
              />
              <Typography variant="body1">{`${CorpFreeBalance.balanceAccountType} - SALDO LIVRE`}</Typography>
              <Typography variant="body1">{CorpFreeBalance.value}</Typography>
            </>
          )}
        </Box>
      </Box>

      <Divider orientation="vertical" flexItem />

      <Box sx={{ width: '70%', padding: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 2,
          }}
        >
          <Typography variant="h6" sx={{ margin: '0 auto' }}>
            HISTÓRICO
          </Typography>
          <Button
            variant="contained"
            onClick={exportToExcel}
            sx={{
              backgroundColor: '#ee7330',
              ':hover': { backgroundColor: '#ee7330' },
            }}
          >
            Exportar para Excel
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {['Ícone', 'Nome', 'Valor', 'Data'].map((headCell) => (
                  <TableCell key={headCell}>
                    <TableSortLabel
                      active={orderBy === headCell.toLowerCase()}
                      direction={
                        orderBy === headCell.toLowerCase() ? order : 'asc'
                      }
                      onClick={() => handleRequestSort(headCell.toLowerCase())}
                    >
                      {headCell}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Avatar sx={{ backgroundColor: '#ffeb3b' }}>
                      <InsertDriveFileIcon />
                    </Avatar>
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.value}</TableCell>
                  <TableCell>{row.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={historyData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 20, 50]}
        />
      </Box>
    </Box>
  );
};

export default InfoAndHistory;
