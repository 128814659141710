// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// @ts-ignore
import { apiCallBegan } from '../api';

import { IState, IAccountHolder } from '../Interfaces/User';
import { ILoginDto } from '../Interfaces/User';
import { Endpoints } from '../../constants/endpoints';
import { IApi, IRequestResponse } from '../middleware/interfaces/api';
import parseJwt from '../../utils/parseJwt';
import getAttributeContains from '../../utils/getAttributeContains';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  isLoggedIn: false,
  loginDate: new Date().toString(),
  access_token: null,
  validCaptcha: false,
  data: undefined,
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    USER_REQUESTED: (user: IState, action: PayloadAction<IState>) => {
      user.loading = true;
      user.error = false;
      user.success = false;
    },

    USER_FAILED: (user: IState, action: PayloadAction<IState>) => {
      user.loading = false;
      user.error = true;
      user.success = false;
      user.validCaptcha = false;
    },

    USER_LOGGED_IN_SUCCESSFULLY: (user: IState, action: PayloadAction<any>) => {
      const { access_token, refresh_token } = action.payload;

      user.loading = false;
      user.error = false;
      user.success = true;
      user.isLoggedIn = true;
      user.access_token = access_token;
      const authData = parseJwt(access_token);
      user.authData = authData;
      user.loginDate = new Date().toString();

      // http://schemas.microsoft.com/ws/2008/06/identity/claims/role
      const roles = getAttributeContains(authData, 'claims/role');
      user.roles = roles;

      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
      user.validCaptcha = false;
    },
    USER_LOGOUT: (user: IState) => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      user.isLoggedIn = false;
      user.access_token = null;
      user.authData = null;
      user.data = undefined;
      user.loading = false;
    },
    GET_USER_BY_ID_SUCCESSFULLY: (
      user: IState,
      action: PayloadAction<IRequestResponse<IAccountHolder>>
    ) => {
      user.data = action.payload.result;
      user.loading = false;
      user.error = false;
    },
  },
});

export const {
  USER_REQUESTED,
  USER_FAILED,
  USER_LOGGED_IN_SUCCESSFULLY,
  USER_LOGOUT,
  GET_USER_BY_ID_SUCCESSFULLY,
} = slice.actions;

export default slice.reducer;

export const loginUser = (LoginDto: ILoginDto) => {
  const formData = new FormData();

  formData.append('grant_type', 'password');
  formData.append('client_id', 'bank');
  formData.append('client_secret', 'secret');
  formData.append('username', LoginDto.email);
  formData.append('password', LoginDto.password);
  formData.append('scope', 'wBankGateway offline_access wExpoPublicAPI');

  const request: IApi = {
    axiosConfig: {
      url: Endpoints.Auth.ConnectToken,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-encoded',
      },
      data: formData,
    },
    onAction: {
      onStart: USER_REQUESTED.type,
      onSuccess: USER_LOGGED_IN_SUCCESSFULLY.type,
      onError: USER_FAILED.type,
    },
  };
  return apiCallBegan(request);
};

export const getUserData = (userId: string) => {
  const request: IApi = {
    axiosConfig: {
      url: Endpoints.AccountHolder.GetIndividualAccountHolder(userId),
      method: 'get',
    },
    onAction: {
      onStart: USER_REQUESTED.type,
      onSuccess: GET_USER_BY_ID_SUCCESSFULLY.type,
      onError: USER_FAILED.type,
    },
  };
  return apiCallBegan(request);
};

/* export const validateCaptcha = (token: string) => {
  const request: IApi = {
    axiosConfig: {
      url: Endpoints.AccountHolder.ValidateCaptcha,
      method: 'post',
      data: { captcha: token },
    },
    onAction: {
      onStart: USER_REQUESTED.type,
      onSuccess: USER_CAPTCHA_SUCCESS.type,
      onError: USER_FAILED.type,
    },
  };
  return apiCallBegan(request);
};
 */
