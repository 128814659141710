import { createTheme, ThemeOptions } from '@mui/material';

export const WexpBlue = {
  400: '#097acf',
  500: '#005391',
  600: '#013964',
};

export const WexpOrange = {
  400: '#f79e6f',
  500: '#EE7330',
  600: '#d46021',
};

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: WexpOrange[500],
      light: WexpOrange[400],
      dark: WexpOrange[600],
    },
    secondary: {
      main: WexpBlue[500],
      light: WexpBlue[400],
      dark: WexpBlue[600],
    },
  },
};

const theme = createTheme(themeOptions);

theme.components = {
  MuiButton: {
    styleOverrides: {
      contained: {
        color: 'white',
        paddingLeft: '2rem',
        paddingRight: '2rem',
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
      },
      containedSizeLarge: {
        paddingLeft: '4rem',
        paddingRight: '4rem',
      },
    },
  },
};

export default theme;
