// Redux
import { combineReducers } from 'redux';

import userReducer from './User';
import balanceAccountGroupReducer from './BalanceAccountGroup';
import statementReducer from './Statement';

const rootReducer = combineReducers({
  user: userReducer,
  balanceAccountGroup: balanceAccountGroupReducer,
  statement: statementReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
