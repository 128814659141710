import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

interface CardItemProps {
  title: string;
  Icon: React.ElementType;
  price: string;
  type: string;
  isYou: boolean; 
}

const CardItem: React.FC<CardItemProps> = ({ title, Icon, price, type, isYou }) => {
  return (
    <>
    <Card
      sx={{
        width: 200,
        height: 150, 
        borderRadius: 4,
        overflow: 'hidden',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          height: '40%',
          backgroundColor: isYou ? '#ee7330' : '#000',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '2px 10px',
        }}
      >
        <Icon sx={{ fontSize: 34, color: '#fff' }} />

        <Typography variant="body2" sx={{ color: '#fff' }}>
          {isYou ? 'You' : 'Corp'}
        </Typography>
      </Box>

      <CardContent
        sx={{
          height: '60%',
          backgroundColor: '#f5f5f5',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start', 
          justifyContent: 'center', 
          gap: 0.5,
          paddingTop: 0,
          paddingLeft: 2, 
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {price}
        </Typography>
        <Typography variant="body2" sx={{ color: '#757575' }}>
          {type}
        </Typography>
      </CardContent>
    </Card>
    </>
  );
};

export default CardItem;
