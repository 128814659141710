import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './containers/Login';
import Home from './containers/Home';
import { PageContainer } from './components/PageContainer';

function App() {
  const isLoggedIn = false;

  return (
    <Routes>
      <Route
        path="/"
        element={
          isLoggedIn === false ? (
            <Navigate to="/login" />
          ) : (
            <Navigate to="/home" />
          )
        }
      />
      <Route path="/login" element={<Login />} />
      <Route
        path="/home"
        element={
          <PageContainer protected>
            <Home />
          </PageContainer>
        }
      />
    </Routes>
  );
}

export default App;
