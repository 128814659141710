import { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

//UI
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import logo from '../assets/images/logo-wexp.png';
import { Box, Button, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getUserData, loginUser } from '../store/Entities/User';
import { RootState } from '../store/Entities';
import { useNavigate } from 'react-router-dom';

interface FormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const { register, handleSubmit } = useForm<FormData>();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstLogin, setFirstLogin] = useState(true);

  const user = useSelector((state: RootState) => state.user);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    dispatch(loginUser(data));
    setFirstLogin(false);
  };

  useEffect(() => {
    if (user.isLoggedIn) {
      dispatch(getUserData(user?.authData?.sub));
      navigate('/home');
    }
  }, [dispatch, navigate, user?.authData?.sub, user?.isLoggedIn]);

  return (
    <Box sx={{ backgroundColor: '#004984' }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          size={{
            xs: 12,
            md: 6,
          }}
          sx={{ minHeight: '50vh', minWidth: '100vw' }}
        >
          <Grid
            size={12}
            maxWidth={600}
            sx={{
              background: 'white',
              padding: '25px 100px',
              borderRadius: '10px',
            }}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                maxWidth: '400px',
              }}
            >
              <img
                style={{
                  width: '150px',
                }}
                src={logo}
                alt=""
              />
              <TextField
                label="Email"
                className="login-input"
                variant="standard"
                fullWidth
                {...register('email')}
              />
              <TextField
                label="Senha"
                className="login-input"
                type="password"
                variant="standard"
                {...register('password')}
                fullWidth
                margin="normal"
              />
              {user.error && !firstLogin && (
                <p style={{ color: 'red' }}>Usuário e/ou senha inválidos</p>
              )}
              {!user.loading && user.success && firstLogin && (
                <p style={{ color: 'red' }}>
                  Não foi possível validar sua solicitação
                </p>
              )}

              <Link
                className="link-password-reset"
                href="https://auth.wexp.com.br/account/ForgotPassword"
              >
                Esqueceu sua senha?
              </Link>

              <div id="recaptcha" />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={user.loading}
                sx={{
                  width: '140px',
                  height: '30px',
                  marginTop: '30px',
                }}
              >
                {user.loading ? (
                  <CircularProgress color={'inherit'} size={25} thickness={5} />
                ) : (
                  'ENTRAR'
                )}
              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
